<template lang="html">
  <div class="row">
    <div class="col-lg-8 col-md-8 col-sm-12">
      <form-card
        v-for="(group, index) of centerGroups"
        :group="group"
        :key="index"
        v-model="form"
      ></form-card>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12">
      <div class="card">
        <div class="card-header">
          <h6 class="title">Banner</h6>
        </div>
        <div class="card-body form-card">
          <my-image v-model="form.banner"></my-image>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h6 class="title">Logo</h6>
        </div>
        <div class="card-body form-card">
          <my-image v-model="form.image"></my-image>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="page-action">
        <el-button @click="cancel">Quay lại</el-button>
        <el-button type="primary" @click="save">Lưu lại</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import FormCard from 'src/components/UIComponents/FormCard.vue'
import { Message, Input, Button } from 'element-ui';
import dataFrom from './charity-form'
import { mapState } from 'vuex'
import { Toast } from 'vuex-toast'
import { mapActions } from 'vuex'
import { ADD_TOAST_MESSAGE } from 'vuex-toast'
import MyImage from 'src/components/UIComponents/Image'

let _form = {}, firstGroups, secondGroups;

export default {
  components: {
    FormCard,
    ElInput: Input,
    ElButton: Button,
    Toast,
    Message,
    MyImage
  },

  beforeCreate() {
    firstGroups = dataFrom[0].groups;
    firstGroups.forEach(group => {
      group.attributes.forEach(attr => {
        if (attr.multiple && _form[attr.prop]) {
          _form[attr.prop] = [];
        } else {
          _form[attr.prop] = '';
        }
        attr.value = _form[attr.prop];
      })
    })
    window.AP = this;
  },

  data () {
    return {
      centerGroups: firstGroups,
      form: _form,
    }
  },

  computed: {
    
  },

  mounted() {
    this.$store.dispatch('setPageTitle', 'Tạo quỹ từ thiện');
    this.$store.dispatch('setCurrentActions', [{
      label: 'Lưu lại',
      type: 'primary',
      icon: '',
      callback: this.save
    }])
  },

  methods: {
    cancel() {
      this.$router.push({name: 'Charity'});
    },
    save() {
      let self = this;
      this.$validator.validateAll().then(function (result) {
        if (result) {
          self.$store.dispatch('storeCharity', self.form).then(res => {
            if (res.success) {
              Message({
                message: 'Tạo mới thành công',
                type: 'success'
              });
              setTimeout(() => {
                self.$router.push('/charities/' + res.data.id);
              }, 1500);
            } else {
              if (res.errors) {
                for (let err in res.errors) {
                  Message({
                    message: res.errors[err][0],
                    type: 'error'
                  });
                }
              } else {
                Message({
                  message: res.message,
                  type: 'error'
                });
              }
            }
          }, error => {
            console.error(error);
          });
        }
      })
    },
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    toast(text, type) {
      this.addToast({
        text,
        type: type,
        dismissAfter: 2000
      })
    }
  },
  watch: {
    'form.name': function(newVal, oldVal) {
      if (newVal) {
        this.form.slug = this.$util.createHandle(newVal);
      }
    }
  },
  destroyed(){
    this.$store.dispatch('setCurrentActions', []);
  }
}
</script>
