var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-lg-8 col-md-8 col-sm-12" },
      _vm._l(_vm.centerGroups, function (group, index) {
        return _c("form-card", {
          key: index,
          attrs: { group: group },
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v
            },
            expression: "form",
          },
        })
      }),
      1
    ),
    _c("div", { staticClass: "col-lg-4 col-md-4 col-sm-12" }, [
      _c("div", { staticClass: "card" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "card-body form-card" },
          [
            _c("my-image", {
              model: {
                value: _vm.form.banner,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "banner", $$v)
                },
                expression: "form.banner",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "card" }, [
        _vm._m(1),
        _c(
          "div",
          { staticClass: "card-body form-card" },
          [
            _c("my-image", {
              model: {
                value: _vm.form.image,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "image", $$v)
                },
                expression: "form.image",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "container-fluid" }, [
      _c(
        "div",
        { staticClass: "page-action" },
        [
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("Quay lại")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("Lưu lại")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", { staticClass: "title" }, [_vm._v("Banner")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", { staticClass: "title" }, [_vm._v("Logo")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }